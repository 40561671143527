import "./Summary.css";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import { Avatar } from "primereact/avatar";
import Grid from "@mui/material/Grid";

function Summary() {
  return (
    <div className="summary">
      <h1 className="summary-title p-4 fw-bold">
        RENDEZ-VOUS LES 22 ET 23 AVRIL 2023 POUR NOTRE 6ÈME ÉDITION DU SALON DE
        L’ANIMALERIE
      </h1>
      <div className="p-4">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
        veniam, quis nostrud exercitation ullamco Lorem ipsum dolor sit amet,
        consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore
        et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
        exercitation ullamco Lorem ipsum dolor sit amet, consectetur adipiscing
        elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
        Ut enim ad minim veniam, quis nostrud exercitation ullamco
      </div>
      <div className="d-flex p-4 justify-content-around ">
        {/* <div className="m-widht-60"> */}
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
          className="justify-content-center"
        >
          <Grid item xs={6} md={8}>
            <h2 className="text-start fw-bold">EN RESUME</h2>
            <List sx={{ width: "100%", bgcolor: "background.paper" }}>
              <ListItem>
                <ListItemAvatar className="align-self-start">
                  <Avatar
                    icon="pi pi-angle-right"
                    className="summary-avatar"
                    shape="circle"
                  />
                </ListItemAvatar>
                <ListItemText
                  primary="50 exposants"
                  secondary="idunt ut labore et dolore magna aliqua. Ut enim ad minim
                veniam, quis nostrud exercitation ullamco Lorem ipsum dolor sit amet,
                consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore
                et dolore magna "
                />
              </ListItem>
              <ListItem>
                <ListItemAvatar className="align-self-start">
                  <Avatar
                    icon="pi pi-angle-right"
                    className="summary-avatar"
                    shape="circle"
                  />
                </ListItemAvatar>
                <ListItemText
                  primary="20 Partenaires"
                  secondary="idunt ut labore et dolore magna aliqua. Ut enim ad minim
        veniam, quis nostrud exercitation ullamco Lorem ipsum dolor sit amet,
        consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore
        et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
        exercitation ullamco Lorem ipsum dolor sit amet, consectetur adipiscing
        elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
        Ut enim ad minim veniam, quis nostrud exercitation ullamco"
                />
              </ListItem>
              <ListItem>
                <ListItemAvatar className="align-self-start">
                  <Avatar
                    icon="pi pi-angle-right"
                    className="summary-avatar"
                    shape="circle"
                  />
                </ListItemAvatar>
                <ListItemText
                  primary="Lorem ipsum"
                  secondary="idunt ut labore et dolore magna aliqua. Ut enim ad minim
        veniam, quis nostrud exercitation ullamco Lorem ipsum dolor sit amet,
        consectetur adipiscing elit, se"
                />
              </ListItem>
            </List>
          </Grid>
          {/* </div> */}
          <Grid item xs={6} md={4}>
            <div className="summary-img">
              <img
                alt="summary"
                width={800}
                height={533}
                src="https://images.unsplash.com/photo-1508997449629-303059a039c0?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTYwfHxleGhpYml0aW9ufGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=800&q=60"
              />
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default Summary;
