import "./FloorPlan.css";
import map from "../../assets/rochexpo.webp";
import { useEffect, useRef, useState } from "react";
import { Tooltip } from "primereact/tooltip";
import FloorPlanDetails from "../floorPlanDetails/FloorPlanDetails";
import { Dialog } from "primereact/dialog";
import { ReactComponent as MapAB } from "../../assets/FloomapAB.svg";
import { ReactComponent as SvgMap } from "../../assets/generalMap.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Menubar } from "primereact/menubar";
import {
  faDog,
  faCat,
  faPlaceOfWorship,
  faSmile,
  faFish,
  faUtensils,
  faWorm,
  faCrow,
} from "@fortawesome/free-solid-svg-icons";

//Id des éléments svg exportés par figma
const areas = [
  {
    id: "Vector",
    color: "#01564B",
    tootipText: "Hall A - Chiens",
    map: MapAB,
    icon: faDog,
  },
  {
    id: "Vector_2",
    color: "#fcd80a",
    tootipText: "Hall B - Serpents",
    map: MapAB,
    icon: faWorm,
  },
  {
    id: "Vector_3",
    color: "#34a8ba",
    tootipText: "Hall A' - Chats",
    map: MapAB,
    icon: faCat,
  },
  {
    id: "Vector_4",
    color: "#f79456",
    tootipText: "FORUM",
    map: MapAB,
    icon: faPlaceOfWorship,
  },
  {
    id: "Vector_5",
    color: "#4823a0",
    tootipText: "Hall H - Bien etre",
    map: MapAB,
    icon: faSmile,
  },
  {
    id: "Vector_6",
    color: "purple",
    tootipText: "Hall C - Cantine",
    map: MapAB,
    icon: faUtensils,
  },
  {
    id: "Vector_7",
    color: "#51bc86",
    tootipText: "Hall D - Poissons",
    map: MapAB,
    icon: faFish,
  },
  {
    id: "Vector_8",
    color: "#b5ccc0",
    tootipText: "Hall E - Oiseaux",
    map: MapAB,
    icon: faCrow,
  },
  {
    id: "Vector_9",
    color: "grey",
    tootipText: "Capacité 200 places",
  },
  {
    id: "Road1",
    tootipText: "Rue des combattants d'Afn",
  },
  {
    id: "Road2",
    tootipText: "Boulevard des Marquis",
  },
  {
    id: "Road3",
    tootipText: "Rue des Centaures",
  },
  {
    id: "ExitA",
    tootipText: "Entrée Salève",
  },
  {
    id: "ExitB",
    tootipText: "Entrée Mont-Blanc",
  },
  {
    id: "ExitC",
    tootipText: "Entrée Aravis",
  },
];

function FloorPlan() {
  //Dialog pour afficher le detail du plan
  const [visible, setVisible] = useState(false);
  //Plan actif à afficher au click
  const ActivePlan = useRef(null);

  useEffect(() => {
    areas.forEach((area) => {
      if (area.color) {
        //Ajout de la couleur des SVG
        document.getElementById(area.id).style.fill = area.color;
        //On ajoute une class svg aux vecteur
        document.getElementById(area.id).classList.add("svg-hall");
      }
      //Ajout de l'attribut tooltip position pour chaque élément
      document.getElementById(area.id).setAttribute("data-pr-position", "top");

      //Ajout d'un écouteur d'evenement au clique sur chaque element du plan
      document.getElementById(area.id).addEventListener("click", (e) => {
        //On récupere l'id de lélément cliqué
        const elId = e.target.id;
        //On recherche le composants map associé à cet id
        const elObject = areas.filter((a) => a.id === elId)[0];
        //s'il y a un plan à afficher
        if (elObject.map) {
          //On le stock dans une variable pour l'afficher
          ActivePlan.current = elObject;
          // on affiche la modale
          setVisible(true);
        }
      });
    });
  });

  let menuItems = areas
    .filter((area) => area.icon)
    .map((area) => {
      return {
        label: area.tootipText,
        icon: (
          <div className="px-2">
            <FontAwesomeIcon icon={area.icon} />
          </div>
        ),
        id: `menuItem-${area.id}`,
        command: (e) => {
          //On le stock dans une variable pour l'afficher
          ActivePlan.current = area;
          // on affiche la modale
          setVisible(true);
        },
      };
    });

  return (
    <div className="floorPlan infos card">
      <div className="floormapNav">
        {/* <Button
          icon="pi pi-search"
          rounded
          text
          raised
          severity="success"
          aria-label="Search"
          onClick={() => {
            setVisibleZoom(true);
          }}
        /> */}
        <Menubar className="justify-content-center mb-3" model={menuItems} />
      </div>

      {areas.map((area) => {
        return (
          <Tooltip key={area.id} target={"#".concat(area.id)}>
            <div className="d-flex flex-column align-items-center">
              <div>{area.icon ? <FontAwesomeIcon icon={area.icon} /> : ""}</div>
              {area.tootipText}
            </div>
          </Tooltip>
        );
      })}

      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="50%"
        viewBox="0 0 800 500"
      >
        <image overflow="visible" href={map} width="100%" height="500px" />
        <SvgMap />
      </svg>

      <Dialog
        header={ActivePlan.current ? ActivePlan.current.tootipText : ""}
        visible={visible}
        style={{ width: "90vw" }}
        onHide={() => setVisible(false)}
      >
        <FloorPlanDetails element={ActivePlan.current} />
      </Dialog>
      {/* <Dialog
        header="Zoom"
        visible={visibleZoom}
        style={{ width: "90vw" }}
        onHide={() => setVisibleZoom(false)}
      >
        <FloorPlanZoom />
      </Dialog> */}
    </div>
  );
}

export default FloorPlan;
