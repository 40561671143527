import "./App.css";
import Header from "./components/header/Header";
import Banner from "./components/banner/Banner";
import Summary from "./components/summary/Summary";
import StyledSections from "./components/UI/styledSections/StyledSections";
import Schedule from "./components/schedule/Schedule";
import Cards from "./components/cards/Cards";
import FloorPlan from "./components/floorPlan/FloorPlan";
import Footer from "./components/footer/Footer";
import Infos from "./components/infos/Infos";

function App() {
  return (
    <div className="App">
      <header>
        <Header />
      </header>
      <main>
        <Banner />
        <Summary />
        <StyledSections title="Actualités" sectionId="actualites">
          <Cards />
        </StyledSections>
        <StyledSections
          title="Programme"
          sectionId="programme"
          //subtitle={<Link onClick={handleDownloadPdf}>Télécharger en PDF</Link>}
        >
          <Schedule />
        </StyledSections>
        <StyledSections
          title="Plan du salon"
          // subtitle={
          //   <a href="/somefile.txt" download>
          //     Télécharger en PDF
          //   </a>
          // }
          sectionId="plan"
        >
          <FloorPlan />
        </StyledSections>
        <StyledSections title="Infos pratiques" sectionId="infos">
          <Infos />
        </StyledSections>
        <div id="back"></div>
      </main>
      <Footer />
    </div>
  );
}

export default App;
