import { Timeline } from "primereact/timeline";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import { Steps } from "primereact/steps";
import { Panel } from "primereact/panel";
import { Menu } from "primereact/menu";
import "./Schedule.css";
import { useEffect, useState } from "react";
import { useRef } from "react";
import Tabs from "@mui/material/Tabs";
import Box from "@mui/material/Box";

const events = [
  {
    status: "Ouverture",
    date: "15/10/2020",
    hour: " 10:30",
    icon: "pi pi-shopping-cart",
    color: "#9C27B0",
    image: "game-controller.jpg",
  },
  {
    status: "Repas",
    date: "15/10/2020",
    hour: " 14:00",
    icon: "pi pi-cog",
    color: "#9C27B0",
  },
  {
    status: "Conférence",
    date: "15/10/2020",
    hour: " 16:00",
    icon: "pi pi-shopping-cart",
    color: "#9C27B0",
  },
  {
    status: "Accueil",
    date: "16/10/2020",
    hour: "08:00",
    icon: "pi pi-check",
    color: "#FF9800",
  },
  {
    status: "Ouverture",
    date: "17/10/2020",
    hour: "08:00",
    icon: "pi pi-cog",
    color: "#607D8B",
  },
  {
    status: "Conférence",
    date: "17/10/2020",
    hour: "15:00",
    icon: "pi pi-cog",
    color: "#607D8B",
  },
  {
    status: "Cocktail de fermeture",
    date: "17/10/2020",
    hour: "17:00",
    icon: "pi pi-check",
    color: "#607D8B",
  },
  {
    status: "Cocktail de fermeture",
    date: "18/10/2020",
    hour: "17:00",
    icon: "pi pi-check",
    color: "#607D8B",
  },
  {
    status: "Cocktail de fermeture",
    date: "18/10/2020",
    hour: "17:00",
    icon: "pi pi-check",
    color: "#607D8B",
  },
  {
    status: "Cocktail de fermeture",
    date: "19/10/2020",
    hour: "17:00",
    icon: "pi pi-check",
    color: "#607D8B",
  },
  {
    status: "Cocktail de fermeture",
    date: "20/10/2020",
    hour: "17:00",
    icon: "pi pi-check",
    color: "#607D8B",
  },
  {
    status: "Cocktail de fermeture",
    date: "21/10/2020",
    hour: "17:00",
    icon: "pi pi-check",
    color: "#607D8B",
  },
  {
    status: "Cocktail de fermeture",
    date: "22/10/2020",
    hour: "17:00",
    icon: "pi pi-check",
    color: "#607D8B",
  },
  {
    status: "Cocktail de fermeture",
    date: "21/10/2020",
    hour: "17:00",
    icon: "pi pi-check",
    color: "#607D8B",
  },
  {
    status: "Cocktail de fermeture",
    date: "22/10/2020",
    hour: "17:00",
    icon: "pi pi-check",
    color: "#607D8B",
  },
  {
    status: "Cocktail de fermeture",
    date: "23/10/2020",
    hour: "17:00",
    icon: "pi pi-check",
    color: "#607D8B",
  },
  {
    status: "Cocktail de fermeture",
    date: "24/10/2020",
    hour: "17:00",
    icon: "pi pi-check",
    color: "#607D8B",
  },
];

//Retourne une string date au format FR avec un séparateur - ou / et le converti en une string date au format iso avec / séparateur
const ISOStringDate = (stringDate) => {
  return stringDate.split(/\/|-/).reverse().join("/");
};

const Schedule = () => {
  //Index actif du composant step
  const [activeIndex, setActiveIndex] = useState(0);
  //Tableau des différentes sections de la timeline
  const sectionsRef = useRef([]);

  //Permet de retourner un index suivant la date de l'évènement afin de créer une ancre pour la navigation et le scroll sur le composant step
  const dates = events.map((event) => event.date);
  const uniqueDates = Array.from(new Set(dates));

  //Fonction qui permet de scroller au clic au premier élément de la timeline correspondant au step
  const scrollToSection = (data) => {
    //On bloque l'effet de scroll sur la navigation
    scroller.current = false;
    const element = document.getElementsByClassName(data);
    if (element[0]) {
      // Will scroll smoothly to the top of the next section
      element[0].scrollIntoView({ behavior: "smooth", block: "center" });
    }
    // //On remet l'effet de scroll à true après l'effet de scroll du clic
    setTimeout(() => {
      scroller.current = true;
    }, 1000);
  };

  //On récupère les jours uniques dans le tableau des evenements, attention, ne marche pas si les dates ne sont pas renseignées de la même maniere
  //Retourne un tableau d'objet avec les jour et les actions associées au clique pour le composant STEP
  const days = events
    .filter(
      (event, index) => index === events.findIndex((e) => e.date === event.date)
    )
    //On transforme ce tableau de date unique en tableau contenant les fonctions associées au step des dates
    .map((event) => {
      ISOStringDate(event.date);
      const date = new Date(ISOStringDate(event.date));
      const dayOptions = {
        weekday: "long",
      };
      const monthOptions = {
        month: "numeric",
        day: "numeric",
      };
      const day = date.toLocaleDateString("fr-FR", dayOptions);
      const month = date.toLocaleDateString("fr-FR", monthOptions);

      return {
        label: (
          <div className="d-flex flex-column">
            <span>{day}</span>
            <span>{month}</span>
          </div>
        ),
        data: event.date,
        command: (e) => {
          scrollToSection(e.item.data);
        },
      };
    });

  //Status défilement utilisateur ou avec la navigation
  const scroller = useRef(true);

  //Detection d'un petit écran
  const [isSmallScreen, setIsSmallScreen] = useState(
    window.innerWidth < 600 ? true : false
  );
  //Menu drowpdown pour petit écran
  const menu = useRef(null);
  //Index actif du composant menu petit écran
  const [activeMenuIndex, setActiveMenuIndex] = useState(dates[0]);

  //Fonction qui modifie le state de la taille d'écran
  const handleResize = () => {
    if (window.innerWidth < 600) {
      setIsSmallScreen(true);
    } else {
      setIsSmallScreen(false);
    }
  };

  //Fonction qui retourne un composant Marker customisé pour le composant timeline
  const customizedTimelineMarker = (item) => {
    return (
      <span
        className="d-flex w-2rem h-2rem align-items-center justify-content-center text-white rounded-circle z-1 shadow-1"
        style={{ backgroundColor: item.color }}
      >
        <span className="schedule-marker">
          {item.date.substring(0, item.date.lastIndexOf("/"))}
        </span>
      </span>
    );
  };

  // const customizedContentForPDF = (item, index) => {
  //   let count = 0;
  //   let nbPages = 1;
  //   let indexElementsToCut = [];

  //   const separatedPages = () => {};

  //   const header = (
  //     <div className="d-flex">
  //       <span className="schedule-date">{item.hour}</span>
  //       <span className="flex-grow-1 text-center">{item.status}</span>
  //     </div>
  //   );

  //   return (
  //     <div
  //       ref={(el) => (sectionsRef.current[index] = el)}
  //       data-date={item.date}
  //       className={`${item.date} panel-schedule`}
  //     >
  //       <Panel header={header}>
  //         <Card title={item.status} subTitle={item.date}>
  //           {item.image && (
  //             <img
  //               src={`https://primefaces.org/cdn/primereact/images/product/${item.image}`}
  //               alt={item.image}
  //               width={200}
  //               className="shadow-1"
  //             />
  //           )}
  //           <p>
  //             Lorem ipsum dolor sit amet, consectetur adipisicing elit.
  //             Inventore sed consequuntur error repudiandae numquam deserunt
  //             quisquam repellat libero asperiores earum nam nobis, culpa ratione
  //             quam perferendis esse, cupiditate neque quas!
  //           </p>
  //           <Button label="Read more" className="p-button-text"></Button>
  //         </Card>
  //       </Panel>
  //     </div>
  //   );
  // };

  //Fonction qui retourne un composant Contenu customisé pour le composant timeline
  const customizedContent = (item, index) => {
    const header = (
      <div className="d-flex">
        <span className="schedule-date">{item.hour}</span>
        <span className="flex-grow-1 text-center">{item.status}</span>
      </div>
    );

    return (
      <div
        ref={(el) => (sectionsRef.current[index] = el)}
        data-date={item.date}
        className={`${item.date} `}
      >
        <Panel header={header} toggleable collapsed>
          <Card title={item.status} subTitle={item.date}>
            {item.image && (
              <img
                src={`https://primefaces.org/cdn/primereact/images/product/${item.image}`}
                alt={item.image}
                width={200}
                className="shadow-1"
              />
            )}
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit.
              Inventore sed consequuntur error repudiandae numquam deserunt
              quisquam repellat libero asperiores earum nam nobis, culpa ratione
              quam perferendis esse, cupiditate neque quas!
            </p>
            <Button label="Read more" className="p-button-text"></Button>
          </Card>
        </Panel>
      </div>
    );
  };

  //Fonction qui retourne un composant Marker customisé pour le composant timeline sur petit écran
  const customizedTimelineMarkerSmallScreen = (item, index) => {
    const header = (
      <div className="d-flex flex-row ">
        <span className="schedule-marker" style={{ color: item.color }}>
          {item.date.substring(0, item.date.lastIndexOf("/"))}
        </span>
        <div className="d-flex flex-column flex-grow-1">
          <span className="schedule-date">{item.hour}</span>
          <span className="flex-grow-1 text-center">{item.status}</span>
        </div>
      </div>
    );
    return (
      <div
        ref={(el) => (sectionsRef.current[index] = el)}
        data-date={item.date}
        className={`${item.date} w-100`}
      >
        <Panel header={header} toggleable collapsed>
          <Card title={item.status} subTitle={item.date}>
            {item.image && (
              <img
                src={`https://primefaces.org/cdn/primereact/images/product/${item.image}`}
                alt={item.image}
                width={200}
                className="shadow-1"
              />
            )}
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit.
              Inventore sed consequuntur error repudiandae numquam deserunt
              quisquam repellat libero asperiores earum nam nobis, culpa ratione
              quam perferendis esse, cupiditate neque quas!
            </p>
            <Button label="Read more" className="p-button-text"></Button>
          </Card>
        </Panel>
      </div>
    );
  };

  //Permet de créer un evenement au scroll pour lier le composants step à la timeline
  useEffect(() => {
    const handleScroll = () => {
      if (!scroller.current) return;
      const sections = sectionsRef.current;
      for (let i = 0; i < sections.length; i++) {
        //La distance entre chaque élément de la timeline et le haut du viewport
        const topViewportSection = sections[i].getBoundingClientRect().top;
        //Si la distance de l'élément par rapport au viewport est positive(pas au dessus) et <300 px(pas au dessous)
        //Quand l'élément rentre dans la zone du viewport entre 0 et 300
        const delta = isSmallScreen ? 100 : 200;
        if (topViewportSection < delta && topViewportSection > 0) {
          const idx = uniqueDates.findIndex(
            (d) => d === sections[i].getAttribute("data-date")
          );
          //On modifie l'index du composant step suivant l'élément dans cette zone du viewport
          setActiveIndex(idx);
          //On modifie l'affichage du bouton de selection du jour
          setActiveMenuIndex(days[idx].data);
        }
      }
    };
    //Creation de l'event listener sur le scroll
    window.addEventListener("scroll", handleScroll);
    //Creation de l'event listener sur la taille de l'écran
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="card" id="schedule">
      {isSmallScreen ? (
        <>
          <div className="sticky-top ">
            <Menu model={days} popup ref={menu} />
            <Button
              className="w-100 my-3"
              icon="pi pi-bars"
              onClick={(e) => menu.current.toggle(e)}
            >
              <span className="px-2">{activeMenuIndex}</span>
            </Button>
          </div>
          <Timeline
            value={events}
            marker={customizedTimelineMarkerSmallScreen}
          />
        </>
      ) : (
        <>
          <Box
            className="sticky-top navBox"
            sx={{ bgcolor: "background.paper" }}
          >
            <div style={{ overflow: "scroll" }}>
              <Steps
                activeIndex={activeIndex}
                onSelect={(e) => setActiveIndex(e.index)}
                model={days}
                readOnly={false}
              />
            </div>

            <Tabs
              value={0}
              className="sticky-top"
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs"
            ></Tabs>
          </Box>
          {/* <Steps
            activeIndex={activeIndex}
            onSelect={(e) => setActiveIndex(e.index)}
            className="sticky-top horizontal-scroll"
            model={days}
            readOnly={false}
          /> */}
          <div>
            <Timeline
              className="mt-3"
              value={events}
              marker={customizedTimelineMarker}
              content={customizedContent}
            />
          </div>
          {/* <div style={{ display: "none" }}>
            <Timeline
              className="mt-3"
              value={events}
              marker={customizedTimelineMarker}
              //content={customizedContentForPDF}
            />
          </div> */}
        </>
      )}
    </div>
  );
};

export default Schedule;
