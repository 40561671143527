import "./Banner.css";

function Banner() {
  return (
    <div className="banner">
      <img
        className="banner-bg"
        src="https://images.unsplash.com/photo-1546445317-29f4545e9d53?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2257&q=80"
        alt=""
      ></img>
      <div className="position-absolute text-white banner-titles">
        <h1 className="fw-bold banner-title">SALON DE L’ANIMALERIE 2023</h1>
        <h2>au palais des congrès</h2>
      </div>
    </div>
  );
}

export default Banner;
