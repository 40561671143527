import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
// import "primereact/resources/themes/lara-light-blue/theme.css"; //theme
import "./themes/themes.css";
import "primereact/resources/primereact.min.css"; //core
import "primeicons/primeicons.css"; //icons
import "bootstrap/dist/css/bootstrap.min.css"; //Bootstrap

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
