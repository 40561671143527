import "./Header.css";
import { TabMenu } from "primereact/tabmenu";
import { Menu } from "primereact/menu";
import { Button } from "primereact/button";
import logo from "../../assets/logoBotanicGreen.png";
import { useEffect, useRef, useState } from "react";

//Fonction permettant de scroller à une section au clic sur la navBar petit écran
const handleClickSmallScreen = (e) => {
  const element = document.getElementById(e.item.sectionId);
  element.scrollIntoView({ behavior: "smooth" });
};

//Eléments de navigation
const nav = [
  {
    label: "Actualités",
    sectionId: "actualites",
    icon: "pi pi-fw pi-home",
    command: (e) => {
      handleClickSmallScreen(e);
    },
  },
  {
    label: "Programme",
    sectionId: "programme",
    icon: "pi pi-fw pi-list",
    command: (e) => {
      handleClickSmallScreen(e);
    },
  },
  {
    label: "Plan",
    sectionId: "plan",
    icon: "pi pi-fw pi-map",
    command: (e) => {
      handleClickSmallScreen(e);
    },
  },
  {
    label: "Infos pratiques",
    sectionId: "infos",
    icon: "pi pi-fw pi-info-circle",
    command: (e) => {
      handleClickSmallScreen(e);
    },
  },
];

function Header() {
  const [activeIndex, setActiveIndex] = useState(-1); //Index de la tabMenu actif
  const menu = useRef(null); //Menu drowpdown pour petit écran
  const sectionsRef = useRef(nav.map((n) => n.sectionId)); //Tableau des différentes sections de l'application
  const scroller = useRef(true); //Status défilement utilisateur ou avec la navigation
  const [isSmallScreen, setIsSmallScreen] = useState(
    window.innerWidth < 820 ? true : false
  ); //Detection d'un petit écran

  const handleScroll = () => {
    if (!scroller.current) return;
    const sections = sectionsRef.current;
    for (let i = 0; i < sections.length; i++) {
      const el = document.getElementById(sections[i]);
      const topViewportSection = el.getBoundingClientRect().top;
      //Si le viewport est dans le header on n'active pas la nagivation
      if (document.body.getBoundingClientRect().top > -500) {
        setActiveIndex(-1);
      }
      if (topViewportSection < 300 && topViewportSection > 0) {
        setActiveIndex(i);
      }
    }
  };

  useEffect(() => {
    //Permet de créer un evenement au scroll pour lier le composants step à la timeline
    window.addEventListener("scroll", handleScroll);
    //Creation de l'event listener sur la taille de l'écran
    window.addEventListener("resize", handleResize);
    //On supprime les event lorsque le composant est démonté
    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  //Fonction qui modifie le state breackpoint
  const handleResize = () => {
    if (window.innerWidth < 820) {
      setIsSmallScreen(true);
    } else {
      setIsSmallScreen(false);
    }
  };

  //Fonction permettant de scroller à une section au clic sur la navBar grand écran
  const handleClick = (e) => {
    //On bloque l'effet de scroll sur la navigation
    scroller.current = false;
    setActiveIndex(e.index);
    const element = document.getElementById(e.value.sectionId);
    element.scrollIntoView({
      behavior: "smooth",
    });
    // //On remet l'effet de scroll à true après l'effet de scroll du clic
    setTimeout(() => {
      scroller.current = true;
    }, 1000);
  };

  return (
    <div className="header">
      <img
        width="147"
        height="47"
        src={logo}
        alt="logo"
        className="header-logo"
      />
      {isSmallScreen ? (
        <>
          <Menu model={nav} popup ref={menu} />
          <Button
            className="m-2"
            icon="pi pi-bars"
            onClick={(e) => menu.current.toggle(e)}
          />
        </>
      ) : (
        <TabMenu
          model={nav}
          activeIndex={activeIndex}
          onTabChange={handleClick}
        />
      )}
    </div>
  );
}

export default Header;
