import styledSections from "./StyledSections.module.css";

function StyledSections(props) {
  return (
    <section id={props.sectionId} className={styledSections.section}>
      <div className="d-flex justify-content-between align-center">
        <h1 className={styledSections.title}>{props.title}</h1>
        <span className={styledSections.subtitle}>{props.subtitle}</span>
      </div>

      {props.children}
    </section>
  );
}

export default StyledSections;
