import { Card } from "primereact/card";
import "./Cards.css";
import { Button } from "primereact/button";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

const exposants = [
  {
    id: 1,
    name: "Pierre et Augustin",
    image:
      "https://images.unsplash.com/photo-1527153857715-3908f2bae5e8?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NHx8Y293fGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=800&q=60",
    summary: "Stand de produits bio",
    details:
      " Lorem ipsum dolor sit amet, consectetur adipisicing elit. Inventore sed consequuntur error repudiandae numquam deserunt quisquam repellat libero asperiores earum nam nobis, ",
  },
  {
    id: 2,
    name: "Pierre et Augustin",
    image:
      "https://images.unsplash.com/photo-1527153857715-3908f2bae5e8?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NHx8Y293fGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=800&q=60",
    summary: "Stand de produits bio",
    details:
      " Lorem ipsum dolor sit amet, consectetur adipisicing elit. Inventore sed consequuntur error repudiandae numquam deserunt quisquam repellat libero asperiores earum nam nobis, ",
  },
  {
    id: 3,
    name: "Pierre et Augustin",
    image:
      "https://images.unsplash.com/photo-1527153857715-3908f2bae5e8?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NHx8Y293fGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=800&q=60",
    summary: "Stand de produits bio",
    details:
      " Lorem ipsum dolor sit amet, consectetur adipisicing elit. Inventore sed consequuntur error repudiandae numquam deserunt quisquam repellat libero asperiores earum nam nobis, ",
  },
  {
    id: 4,
    name: "Pierre et Augustin",
    image:
      "https://images.unsplash.com/photo-1527153857715-3908f2bae5e8?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NHx8Y293fGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=800&q=60",
    summary: "Stand de produits bio",
    details:
      " Lorem ipsum dolor sit amet, consectetur adipisicing elit. Inventore sed consequuntur error repudiandae numquam deserunt quisquam repellat libero asperiores earum nam nobis, ",
  },
  {
    id: 5,
    name: "Pierre et Augustin",
    image:
      "https://images.unsplash.com/photo-1527153857715-3908f2bae5e8?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NHx8Y293fGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=800&q=60",
    summary: "Stand de produits bio",
    details:
      " Lorem ipsum dolor sit amet, consectetur adipisicing elit. Inventore sed consequuntur error repudiandae numquam deserunt quisquam repellat libero asperiores earum nam nobis, ",
  },
];

function Cards() {
  const footer = (
    <div className="flex flex-wrap justify-content-end gap-2">
      <Button label="More" icon="pi pi-check" />
    </div>
  );

  return (
    <div className="cards">
      <Box sx={{ flexGrow: 1 }}>
        <Grid
          container
          spacing={{ xs: 2, md: 4 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          {exposants.map((exposant) => {
            const header = (
              <img className="card-exp" alt="Card" src={exposant.image} />
            );
            return (
              <Grid item xs={4} key={exposant.id}>
                <Card
                  title={exposant.name}
                  subTitle={exposant.summary}
                  footer={footer}
                  header={header}
                  className="md:w-25rem"
                >
                  <p className="m-0">{exposant.details}</p>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </div>
  );
}

export default Cards;
