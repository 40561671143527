import "./Infos.css";
import Grid from "@mui/material/Grid";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import MapboxDirections from "@mapbox/mapbox-gl-directions/dist/mapbox-gl-directions";
import { useEffect, useRef } from "react";
import "mapbox-gl/dist/mapbox-gl.css";
import "@mapbox/mapbox-gl-directions/dist/mapbox-gl-directions.css";

function Infos() {
  //Variables du plan
  mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;
  const mapContainer = useRef(null);
  const map = useRef(null);
  const lng = 6.307181;
  const lat = 46.073899;
  const zoom = 15;
  // const [lng, setLng] = useState(6.307181);
  // const [lat, setLat] = useState(46.073899);
  // const [zoom, setZoom] = useState(9);

  useEffect(() => {
    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      language: "fr-FR",
      steps: true,
      style: "mapbox://styles/mapbox/streets-v12",
      center: [lng, lat],
      zoom: zoom,
    });
    // Create a default Marker and add it to the map.
    new mapboxgl.Marker({
      color: "var(--primary-color)",
      draggable: true,
    })
      .setLngLat([lng, lat])
      .addTo(map.current);

    //On ajoute la possibilité de créer un itinéraire
    const directions = new MapboxDirections({
      accessToken: mapboxgl.accessToken,
      steps: true,
      unit: "metric",
      profile: "mapbox/driving",
      language: "Fr",
      geocoder: {
        language: "Fr",
      },
      placeholderOrigin: "Lieu de départ",
      placeholderDestination: "Lieu d'arrivée",
    });

    //On positionne le départ sur le lieu de l'évènement au chargement de la carte
    map.current.on("load", (e) => {
      directions.setDestination([lng, lat]);
      map.current.setLayoutProperty("country-label", "text-field", [
        "get",
        "name_fr",
      ]);
    });

    // Add navigation control (the +/- zoom buttons)
    //Ajout des controles pour l'itinéraire
    map.current.addControl(directions, "top-left");

    //Modification des noms de label (pas d'option language)
    const labels = document.querySelectorAll("label");
    [...labels].forEach((label) => {
      if (label.innerHTML === "Traffic") label.innerHTML = "Circulation";
      if (label.innerHTML === "Driving") label.innerHTML = "Voiture";
      if (label.innerHTML === "Walking") label.innerHTML = "A pied";
      if (label.innerHTML === "Cycling") label.innerHTML = "Vélo";
    });

    // Clean up on unmount
    return () => map.remove();
  });

  return (
    <div className="infos card">
      <div className="d-flex  justify-content-around p-4">
        <Grid
          container
          wrap="wrap-reverse"
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
          className="justify-content-center"
        >
          <Grid item xs={12} md={4}>
            <div className="infos-img">
              <img
                alt="infos"
                src="https://images.unsplash.com/photo-1632383380286-80f79eb1bae1?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1160&q=80"
              />
            </div>
          </Grid>
          {/* </div> */}
          <Grid item xs={12} md={8} className="d-flex">
            <div className="d-flex justify-content-between align-items-start flex-column w-100">
              <div className="text-start my-12">
                <h2 className="fw-bold">LE LIEU</h2>
                <div>
                  ROCHEXPO – Association Foire Exposition Haute-Savoie
                  Mont-Blanc
                </div>
                <div>59 Rue des Centaures F-74801</div>
                <div>La Roche-sur-Foron Cedex</div>
              </div>
              <div className="text-start my-12">
                <h2 className="fw-bold">DATES</h2>
                <span>Samedi 22 avril & Dimanche 23 avril 2023</span>
              </div>
              <div className="text-start my-12">
                <h2 className="fw-bold">HORAIRES</h2>
                <span className="text-start">
                  de 12h à 23h le samedi avec la soirée officielle du Salon de
                  12h à 19h le dimanche
                </span>
              </div>
              <div className="text-start my-12">
                <h2 className="fw-bold">VENIR AU SALON</h2>
                <div className="text-start my-6">
                  <h6 className="fw-bold ">En voiture</h6>
                  <div>Depuis geneve : Lorem ipsum</div>
                  <div>Depuis l'A45 : Lorem ipsum</div>
                </div>
                <div className="text-start">
                  <h6 className="fw-bold ">En train</h6>
                  <div>Depuis geneve : Lorem ipsum</div>
                  <div>Depuis l'A45 : Lorem ipsum</div>
                </div>
              </div>

              <div ref={mapContainer} className="map-container" />
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default Infos;
