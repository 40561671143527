import "./FloorPlanDetails.css";
// import { ReactComponent as Map } from "../../assets/FloomapAB.svg";
import { Tooltip } from "primereact/tooltip";

function FloorPlanDetails(props) {
  const Map = props.element.map;

  return (
    <div className="floorPlanDetails infos card">
      <Tooltip target=".svg-hall-A" />
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="50%"
        viewBox="0 0 800 500"
      >
        <Map />
      </svg>
    </div>
  );
}

export default FloorPlanDetails;
